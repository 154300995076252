<template>
    <div class="report">
        <div class="row mb-4">
            <div class="col-12">
                <h4>Tracking Report: <small class="text-muted">{{report.name}} <button type="button" @click="report=null" class="btn btn-danger btn-sm">Close</button></small></h4>
            </div>
        </div>

        <div class="row stats mb-4">
            <div class="col-12 col-sm-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{Number(report.stats.today||0).toLocaleString()}} hits</h4>
                        <h5>Today</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{Number(report.stats.yesterday||0).toLocaleString()}} hits</h4>
                        <h5>Yesterday</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{Number(report.stats.month||0).toLocaleString()}} hits</h4>
                        <h5>This Month</h5>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-3">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{Number(report.stats.total||0).toLocaleString()}} hits</h4>
                        <h5>Total</h5>
                    </div>
                </div>
            </div>
        </div>

        <div class="row hours mb-4">
            <div class="col-12">
                <div class="chart">
                    <line-chart :data="report.chartdata.data" area />
                </div>
            </div>
            <!--<div class="col-12 col-sm-2 mb-1" v-for="(hour,x) in report.hours" v-bind:key="x">
                <div class="card">
                    <div class="card-body text-center">
                        <h4>{{Number(hour||0).toLocaleString()}} hits</h4>
                        <h5>{{x}} hour</h5>
                    </div>
                </div>
            </div>-->
        </div>
    </div>
</template>

<script>
    import moment from "moment-timezone";
    import axios from "axios";
    import swal from "sweetalert";

    export default {
        name: "workers.Report",
        data: function () {
            return {
                timezones: moment.tz.names(),
                clients:[],
                item: {
                    timezone: ''
                },
                customerQuery:'',
                countries:[],
                states:[],
                cities:[],
                report: null,
                filter: {name:'', client:'', proxy:'', status:'',country:'',state:''},
                max: 25,
                page: 1,
                bootstrapPaginationClasses: {
                    ul: 'pagination',
                    li: 'page-item',
                    liActive: 'active',
                    liDisable: 'disabled',
                    button: 'page-link'
                },
                customLabels: {
                    first: 'First',
                    prev: 'Previous',
                    next: 'Next',
                    last: 'Last'
                }
            }
        },
        created() {
            this.reports();
        },
        methods:{
            reports: function(obj){
                //Show reports on the item
                this.editor = false
                this.$root.preloader = true

                var request = {'apikey': this.$root.apikey}
                this.$root.preloader = true

                axios.get(`${this.$root.serverUrl}/admin/queue/active}`, {params:request}).then((resp)=>{
                    //Store the stats
                    this.report = Object(obj, {});
                    this.report.stats = resp.data.data;
                    /*this.report.chartdata = {data:[{name: this.report.name, "x-name": "Hour", "y-name": "Hits", data: []}]};
                    for(var i in this.report.stats.hourly){
                        this.report.chartdata.data[0].data.push({label: `${i} Hour`, value:this.report.stats.hourly[i]})
                    }*/
                    this.$root.preloader = false
                }).catch((err)=>{
                    console.log('report.error', err)
                    swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
                })
            },
        }
    }
</script>

<style scoped>

</style>
